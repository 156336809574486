import { initializeApp } from 'firebase/app'

const firebaseConfig = {
  apiKey: 'AIzaSyD0tPkiGpZj_34VD7MrKKWg4z5zUc2t_GE',
  authDomain: 'cherry-tree-258710.firebaseapp.com',
  projectId: 'cherry-tree-258710',
  storageBucket: 'cherry-tree-258710.appspot.com',
  messagingSenderId: '335940237421',
  appId: '1:335940237421:web:532c84d8f1eb7ba2476764',
  measurementId: 'G-TEPH40FFB4',
}

export const app = initializeApp(firebaseConfig)
