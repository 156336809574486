import { createContext, useState } from 'react'
import { app } from './firebase'
import { getAuth, onAuthStateChanged } from 'firebase/auth'

export const UserContext = createContext({
  user: null,
  initialised: null,
  logOut: null,
})

export const UserContextProvider = ({ children }) => {
  const [user, setUser] = useState(null)
  const [initialised, setInitialised] = useState(false)

  const auth = getAuth(app)

  onAuthStateChanged(auth, (fbUser) => {
    if (fbUser) {
      handleLogin(fbUser)
    } else if (user) {
      handleLogout()
    }
    if (initialised === false) setInitialised(true)
  })

  const handleLogin = (user) => {
    // console.log('logging in user')
    setUser(user)
  }

  const handleLogout = () => {
    // console.log('logging out user')
    setUser(null)
  }

  const logOut = () => {
    auth.signOut()
  }

  return (
    <UserContext.Provider value={{ user, initialised, logOut }}>
      {children}
    </UserContext.Provider>
  )
}
